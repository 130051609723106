import { ActivatedRouteSnapshot, Router } from '@angular/router';
import { inject, Injectable } from '@angular/core';
import { StorageContextService } from '../../../../context/storage-context/storage-context.service';
import { UserContextService } from '../../../../context/user-context/user-context.service';
import { catchError, tap } from 'rxjs/operators';
import { lastValueFrom, of } from 'rxjs';

@Injectable()
export class OptionalAuthGuard {
  private readonly router = inject(Router);

  constructor(
    private storageContext: StorageContextService,
    private userContext: UserContextService
  ) {}

  async canActivate(route: ActivatedRouteSnapshot): Promise<boolean> {
    const apiKey = route.queryParamMap.get('ApiKey') || route.queryParamMap.get('apiKey');
    if (apiKey) {
      this.storageContext.setItem('access_token', apiKey.replace(/\s/g, '+'));
      await lastValueFrom(
        this.userContext.retrieve().pipe(
          tap(user => this.userContext.next(user)),
          catchError(async () => {
            this.storageContext.removeItem('access_token');
            const queryParams = { ...route.queryParams, ApiKey: undefined, apiKey: undefined };
            await this.router.navigate(
              route.pathFromRoot
                .map(p => p.url)
                .flat()
                .map(({ path }) => path),
              { queryParams, replaceUrl: true }
            );
            return of(true);
          })
        )
      );
    }
    return true;
  }
}
