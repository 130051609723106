import { inject, Injectable } from '@angular/core';
import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpHeaders,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { UserContextService } from '../../../context/user-context/user-context.service';
import { StorageContextService } from '../../../context/storage-context/storage-context.service';
import { PackagesContextService } from '../../../context/packages-context/packages-context.service';
import { catchError } from 'rxjs/operators';
import { GoogleAnalyticsService } from '../google-analytics/google-analytics.service';
import { version } from '../../../../environments/version';
import { AppConfiguration } from '../configuration/app.configuration';
import dayjs from 'dayjs';
import { ActivatedRoute, Router } from '@angular/router';

type Headers = { [key: string]: string };

@Injectable()
export class AccessTokenInterceptorService implements HttpInterceptor {
  private readonly router = inject(Router);
  private readonly route = inject(ActivatedRoute);

  constructor(
    private googleAnalyticsService: GoogleAnalyticsService,
    private storageContext: StorageContextService,
    private packagesContext: PackagesContextService,
    private userContext: UserContextService,
    private storageService: StorageContextService,
    private appConfiguration: AppConfiguration
  ) {}

  intercept(req: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    const headers = this.setHeaders(req);

    req = req.clone({
      headers: new HttpHeaders(headers),
    });

    return next.handle(req).pipe(
      catchError((err: HttpErrorResponse): Observable<HttpEvent<unknown>> => {
        if (err.error) {
          const error = err.error;

          if (error.message) {
            return AccessTokenInterceptorService.throw(error.message, String(err.status));
          }

          if (error.error_description) {
            return this.throwGrantError(error, err.status);
          }

          if (error.error) {
            return AccessTokenInterceptorService.throw(error.error);
          }

          if (error.form) {
            return this.throwFormError(error);
          }
        }

        console.log(err);

        return AccessTokenInterceptorService.throw('Connection error. Please try again later.');
      })
    );
  }

  static throw(message: string, code?: string): Observable<never> {
    const error = new Error(message);
    error.name = code || 'BackendError';
    return throwError(error);
  }

  private throwFormError(error: any): Observable<never> {
    return AccessTokenInterceptorService.throw(
      Object.keys(error.form.errors)
        .filter(key => !!key)
        .map(key => `* ${error.form.errors[key][0]} (${key})`)
        .join('\n'),
      'FormError'
    );
  }

  private throwGrantError(err: any, status: number): Observable<never> {
    if (err.error === 'invalid_grant' && status === 403) {
      setTimeout(() => {
        this.clearUserData();
      }, 3000);
    }
    return AccessTokenInterceptorService.throw(err.error_description, String(status));
  }

  private clearUserData() {
    if (this.storageService.getItem('access_token')) {
      sessionStorage.clear();
      this.storageContext.clear();
      window.location.reload();
    }
  }

  private isGoogleApiRequest(req: HttpRequest<unknown>): boolean {
    return req?.url.includes('googleapis.com');
  }

  private isGeoApiRequest(req: HttpRequest<unknown>): boolean {
    return req?.url.includes('ipapi.co');
  }

  private isRunwayerApiRequest(req: HttpRequest<unknown>): boolean {
    return req?.url.includes('https://app.runwayer.com/');
  }

  private isStripeRequest(req: HttpRequest<unknown>): boolean {
    return req?.url.includes('api.stripe');
  }

  private setHeaders(req: HttpRequest<unknown>): Headers {
    const headers: Headers = {};
    const accessToken = this.storageContext.getItem('access_token');

    if (
      !this.isGoogleApiRequest(req) &&
      !this.isGeoApiRequest(req) &&
      !this.isStripeRequest(req) &&
      !this.isRunwayerApiRequest(req)
    ) {
      const attributes = {
        _fbc: this.storageService.getItem('_fbc') || undefined,
        _fbp: this.storageService.getItem('_fbp') || undefined,
        _ga: this.storageService.getItem('_ga') || undefined,
        _gid: this.storageService.getItem('_gid') || undefined,
        _ttp: this.storageService.getItem('_ttp') || undefined,
      };

      headers['x-app-version'] = `web:${version}`;
      headers['x-attribute'] = JSON.stringify(attributes);

      const tz = dayjs.tz.guess();
      if (tz) {
        headers['x-time-zone'] = tz;
      }
    }

    if (this.isStripeRequest(req)) {
      headers['Authorization'] = `Basic ${this.appConfiguration.stripeToken}`;
    }

    if (
      accessToken &&
      !this.isGoogleApiRequest(req) &&
      !this.isStripeRequest(req) &&
      !this.isRunwayerApiRequest(req)
    ) {
      headers['x-token'] = `ApiKey="${accessToken.replace(/\s/g, '+')}"`;
      headers['X-Frame-Options'] = 'SAMEORIGIN';

      if (this.googleAnalyticsService.getClientId()) {
        headers['x-ga-client-id'] = this.googleAnalyticsService.getClientId() ?? '';
      }

      if (this.googleAnalyticsService.getSessionGtagId()) {
        headers['ga-session'] = this.googleAnalyticsService.getSessionGtagId() ?? '';
      }
    }
    return headers;
  }
}
